<template>
  <div class="forget-wrapper">
    <div class="forget-left">
      <signInUpLeft />
    </div>
    <div class="forget-right">
      <div class="forget-content" >
        <div class="step">
          <step :current="current"></step>
        </div>
        <div class="reset-success" v-if="showSuccessModal">
          <div class="sucess-icon">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-success-tianchong"></use>
            </svg>
          </div>
          <div class="redirect-tip">
            Please check your mailbox for instructions to reset your password.
          </div>
          <div class="success-btn">
            <artmisads-button type="primary" @click="resendEmail"
              >Resend Email</artmisads-button
            >
          </div>
        </div>
        <div v-else>
          <div class="email">
            <artmisads-input
              :class="status"
              v-model:value="emailValue"
              placeholder="Please input your registration email."
              @blur="onInputChange"
            ></artmisads-input>
            <div class="validate-error" v-if="error">{{ error }}</div>
          </div>
          <div class="reset">
            <artmisads-button type="primary" @click="onReset"
              >Send the verification email</artmisads-button
            >
          </div>
          <a class="cancel" @click="cancel">Cancel</a>
        </div>
      </div>
    </div>
    <contextHolder />
  </div>
</template>
<script setup>
import { ref, reactive, toRaw, h } from "vue";
import { signApi } from "../../server/index";
import { useRouter } from "vue-router";
import { Form, message, notification } from 'ant-design-vue';
import signInUpLeft from "../../components/signInUpLeft.vue";
import step from "./step.vue";
let showSuccessModal = ref(false);
let status=ref('');
let current = ref(1);
let emailValue = ref();
let error = ref("");
const router = useRouter();
const [api, contextHolder] = notification.useNotification();
  const openNotification = description => {
    api.info({
      message: `Warning`,
      description: description,
      placement: 'topRight',
      duration: 10
    });
  };
const onReset = (e) => {
  
  signApi.emailValidate({ email: emailValue.value }).then((res) => {
    if (!res.data) {
      status='ant-input-status-error';
      error.value = "This email address does not exist in our records.";
      return;
    } else {
      signApi.passwordEmail({ email: emailValue.value }).then((res) => {
        if(res.success){
          showSuccessModal.value = true;
        }else{
          openNotification(res.error);
        }
        
     });
     
    }
  });
};
const cancel = () => {
  router.push({ name: "sign-in" });
};
const onInputChange = () => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  let isTrue = emailRegex.test(emailValue.value);
  if (!isTrue) {
    status.value='ant-input-status-error';
    error.value = "The email format is invalid.";
  }else{
    status=" ";
    error.value = " ";
  }
};
function resendEmail() {
  emailValue.value = undefined;
  showSuccessModal.value = false;
}
</script>
<style lang="less" scoped>
@media screen and (min-width: 960px) {
  .forget-wrapper {
    width: 100dvw;
    height: 100dvh;
    min-height: 700px;
    overflow: hidden;
    display: flex;
  }
  .forget-right {
    display: flex;
    justify-content: center;
    // background: #F4F2FA;
    flex: 1;
    position: relative;
  }
  .forget-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    // justify-content: center;
    .step:deep {
      width: 541px;
      margin-bottom: 64px;
      .ant-steps-item-finish {
        .ant-steps-item-icon {
          background-color: #7d58e5;
          .ant-steps-finish-icon {
            color: #ffffff;
          }
        }
      }
      .ant-steps-item-title {
        white-space: nowrap;
      }
    }
    .email {
      width: 400px;
      margin-bottom: 40px;
      .ant-input {
        height: 40px;
      }
      .validate-error {
        font-weight: 400;
        color:var(--red-text);
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.3px;
      }
    }
    .reset {
      width: 400px;
      margin-bottom: 16px;
      button {
        width: 100%;
        height: 40px;
        box-shadow: 0 1px 0 0 #1a16250d;
        border-radius: 20px;
        font-family: Sora;
        font-weight: 500;
        font-size: 15px;
        color: #ffffff;
        letter-spacing: 0.4px;
        text-align: center;
      }
    }
    .cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-family: Sora;
      font-size: 13px;
      letter-spacing: 0.3px;
      line-height: 18px;
      text-decoration: underline;
      color: var(--dark-3);
      &:hover {
        color: var(--dark-2);
      }
    }
  }
  .reset-success{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .sucess-icon{
      padding: 4px;
      background-color:#D7E9E4 ;
      border-radius: 50%;
      .icon{
        font-size: 51px;
        fill: #2AAE67;
      }
    }
    .redirect-tip{
      width: 400px;
      margin: 12px 0 24px;
      font-family: Sora;
      font-size: 16px;
      color: var(--dark-2);
      letter-spacing: 0.4px;
      line-height: 22px;
    }
    .success-btn {
      width: 400px;
      button {
        width: 100%;
        height: 40px;
        font-family: Sora;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0.4px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .forget-wrapper {
    width: 100dvw;
    height: 100dvh;
    overflow: hidden;
    display: flex;
  }
  .forget-right {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .forget-content {
      align-self: center;
      position: relative;
      z-index: 1;
      width: calc(100dvw - 32px);
      padding: 24px 32px;
      width: 343px;
      background: #ffffff;
      box-shadow: 0 1px 0 0 #1a16250d;
      border-radius: 16px;
    }
    .step:deep {
      width: 500px;
      margin-bottom: 48px;
      .ant-steps-item-finish {
        .ant-steps-item-icon {
          background-color: #7d58e5;
          .ant-steps-finish-icon {
            color: #ffffff;
          }
        }
      }
      .ant-steps-item-title {
        white-space: nowrap;
      }
    }
    .email {
      width: 300px;
      .ant-input {
        height: 40px;
      }
      .validate-error {
        color: #eb4e3d;
        margin-top: 8px;
      }
    }
    .reset {
      width: 300px;
      padding-top: 24px;
      padding-bottom: 48px;
      button {
        width: 100%;
        height: 40px;
        box-shadow: 0 1px 0 0 #1a16250d;
        border-radius: 20px;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 15px;
        color: #ffffff;
        letter-spacing: 0.4px;
        text-align: center;
      }
    }
    .cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
